import React, { useState } from 'react';

import { graphql, useStaticQuery, Link } from 'gatsby';
import { Button, Modal } from 'react-bootstrap';
import { HiSparkles } from 'react-icons/hi';
import { MdCheck } from 'react-icons/md';

import FlodeskModal from '../components/Flodesk/SignupModal';
import '../scss/membership.scss';

const PricingPage = () => {
  const { allContentfulPricingChart } = useStaticQuery(graphql`
    query pricingPageQuery {
      allContentfulPricingChart {
        nodes {
          mainHeader
          mainSubheader
          sequinBasicData {
            callout
            header
            subheader
            buttonText
            valueProps {
              text
              subtext
              modalTrigger
              modalContentID
              modalContent {
                header
                icon
                list
                finePrint
              }
            }
            tagText
          }
          sequinMembershipData {
            callout
            header
            subheader
            buttonText
            valueProps {
              text
              subtext
            }
            tagText
          }
        }
      }
    }
  `);

  const [flodeskModalShow, setFlodeskModalShow] = useState(false);
  const [infoModalShow, setInfoModalShow] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const chartData = allContentfulPricingChart.nodes[0];
  const { sequinBasicData, sequinMembershipData } = chartData;

  const processTextForSuperscript = text => {
    const regex = /\[([^\]]+)\](?:\(([^)]*)\))?/g;
    const parts = [];
    let match;

    const scrollToBottom = e => {
      e.preventDefault();
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: 'smooth'
      });
    };

    let lastIndex = 0;
    while ((match = regex.exec(text)) !== null) {
      const textBeforeMatch = text.slice(lastIndex, match.index);
      if (textBeforeMatch) parts.push(textBeforeMatch);

      const character = match[1];
      const url = match[2];

      if (url === '#') {
        parts.push(
          <sup key={`sup-${lastIndex}`} style={{ paddingLeft: '3px' }}>
            <a href="#" className="purple-link" onClick={scrollToBottom}>
              {character}
            </a>
          </sup>
        );
      } else if (url) {
        parts.push(
          <sup key={`sup-${lastIndex}`} style={{ paddingLeft: '3px' }}>
            <a
              href={url}
              className="purple-link"
              target="_blank"
              rel="noopener noreferrer"
            >
              {character}
            </a>
          </sup>
        );
      } else {
        parts.push(<sup key={`sup-${lastIndex}`}>{character}</sup>);
      }

      lastIndex = regex.lastIndex;
    }

    if (lastIndex < text.length) parts.push(text.slice(lastIndex));

    return parts;
  };

  const renderPricingItems = valueProps =>
    valueProps.map(
      (
        { text, subtext, modalTrigger, modalContentID, modalContent },
        index
      ) => (
        <div key={index} className="value-props d-flex flex-column">
          <div className="d-flex flex-row align-items-top">
            <MdCheck color="#60034c" />
            <div className="text-items">
              <p className="value-props-text">
                {processTextForSuperscript(text)}
              </p>
              {modalTrigger ? (
                <div
                  className={`purple-link ${modalContentID ? 'clickable-subtext' : ''
                    }`}
                  onClick={() => {
                    setModalContent(modalContent);
                    setInfoModalShow(true);
                  }}
                >
                  {subtext}
                </div>
              ) : (
                <p>{subtext}</p>
              )}
            </div>
          </div>
        </div>
      )
    );

  const renderModalContent = () => {
    const IconComponent = modalContent.icon ? HiSparkles : null;
    return (
      <Modal.Body className="fee-modal">
        {modalContent.header && (
          <h3>
            <span dangerouslySetInnerHTML={{ __html: modalContent.header }}></span> {IconComponent && <IconComponent />}
          </h3>
        )}
        {modalContent.list && (
          <ul>
            {modalContent.list.map((item, index) => (
              <>
                {index !== 0 && <div className="or">or</div>}
                <li key={index} dangerouslySetInnerHTML={{ __html: item }}></li>
              </>
            ))}
          </ul>
        )}
        {modalContent.finePrint && (
          <div className="fine-print p-3" dangerouslySetInnerHTML={{ __html: modalContent.finePrint }} />
        )}
      </Modal.Body>
    );
  };

  return (
    <div className="membership-container">
      <h1 className="chart-header">{chartData.mainHeader}</h1>
      <p className="chart-subheader">{chartData.mainSubheader}</p>
      <div className="pricing-chart">
        <div className="pricing-chart-item">
          {sequinMembershipData.tagText && (
            <div className="recommended-tag">
              {sequinMembershipData.tagText}
            </div>
          )}
          <div className="card-top d-flex flex-column">
            <h3 className="callout">{sequinMembershipData.callout}</h3>
            <h2 className="pricing-item-header">
              {sequinMembershipData.header}
            </h2>
            <p className="pricing-item-subheader">
              {sequinMembershipData.subheader}
            </p>
            <Button
              onClick={() => setFlodeskModalShow(true)}
              className="purple-button m-0-auto mb-4"
            >
              {sequinMembershipData.buttonText}
            </Button>
          </div>
          {renderPricingItems(sequinMembershipData.valueProps)}
        </div>

        <div className="pricing-chart-item">
          {sequinBasicData.tagText && (
            <div className="recommended-tag">{sequinBasicData.tagText}</div>
          )}
          <div className="card-top d-flex flex-column">
            <h3 className="callout">
              {processTextForSuperscript(sequinBasicData.callout)}
            </h3>
            <h2 className="pricing-item-header">
              {processTextForSuperscript(sequinBasicData.header)}
            </h2>
            <p className="pricing-item-subheader">
              {processTextForSuperscript(sequinBasicData.subheader)}
            </p>
            <Button
              onClick={() => setFlodeskModalShow(true)}
              className="purple-button m-0-auto mb-4"
            >
              {sequinBasicData.buttonText}
            </Button>
          </div>
          {renderPricingItems(sequinBasicData.valueProps)}
        </div>
      </div>
      <Modal
        show={infoModalShow}
        onHide={() => setInfoModalShow(false)}
        centered
      >
        {renderModalContent()}
      </Modal>
      <FlodeskModal
        show={flodeskModalShow}
        onHide={() => setFlodeskModalShow(false)}
      />
    </div>
  );
};

export default PricingPage;
